import { defineComponent } from '@vue/composition-api';
var defaultQuote = {
    quote: 'Wherever you are - be all there.',
    source: 'Jim Elliot'
};
export default defineComponent({
    name: 'Quote',
    props: {
        quoteList: {
            type: Array,
            default: function () { return []; }
        },
        counter: {
            type: Number,
            default: 0
        }
    },
    computed: {
        quote: function () {
            var q = this.quoteList;
            return q.length === 0
                ? defaultQuote
                : q[this.counter % q.length];
        }
    }
});
