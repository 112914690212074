export default [
    {
        quote: 'Count it all joy, my brothers, when you meet trials of various kinds, for you know that the testing of your faith produces steadfastness.',
        source: 'James 1:2-3'
    },
    {
        quote: 'Rejoice in the Lord always; again I will say, rejoice.',
        source: 'Philippians 4:4'
    },
    {
        quote: 'For all have sinned and fall short of the glory of God.',
        source: 'Rom 3:23'
    },
    {
        quote: 'You keep him in perfect peace whose mind is stayed on you, because he trusts in you.',
        source: 'Isaiah 26:3'
    }
];
