import sortBy from 'lodash/sortBy';
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    name: 'Items',
    props: {
        items: {
            type: Array,
            default: function () { return []; }
        }
    },
    computed: {
        sortedItems: function () {
            return sortBy(this.items, 'complete');
        }
    }
});
