import { ref, computed, onMounted, onUnmounted } from '@vue/composition-api';
import { DEFAULT_INTERVAL, FIFTEEN_MINUTES, FIVE_SECONDS, THIRTY_SECONDS } from '@/constants';
export function createTimer(start) {
    if (start === void 0) { start = Date.now(); }
    var initial = ref(start);
    var now = ref(start);
    var createComputedPeriod = function (period) { return function () {
        return Math.floor((now.value - initial.value) / period);
    }; };
    var thirtySeconds = computed(createComputedPeriod(THIRTY_SECONDS));
    var fiveSeconds = computed(createComputedPeriod(FIVE_SECONDS));
    var fifteenMinutes = computed(createComputedPeriod(FIFTEEN_MINUTES));
    function t() {
        now.value = Date.now();
    }
    var intervalRef;
    onMounted(function () {
        intervalRef = window.setInterval(t, DEFAULT_INTERVAL);
    });
    onUnmounted(function () {
        window.clearInterval(intervalRef);
    });
    return {
        now: now,
        thirtySeconds: thirtySeconds,
        fiveSeconds: fiveSeconds,
        fifteenMinutes: fifteenMinutes
    };
}
