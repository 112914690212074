import ItemList from '@/ItemList.vue';
import Quote from '@/Quote.vue';
import quotes from '@/data/quotes.ts';
import itemLists from '@/data/itemLists';
import { createTimer } from '@/Timer.ts';
import { computed, defineComponent } from '@vue/composition-api';
import { FIFTEEN_MINUTES, FIVE_SECONDS } from '@/constants';
import useCheckBuildTime from '@/CheckBuild';
export default defineComponent({
    name: 'App',
    components: { ItemList: ItemList, Quote: Quote },
    setup: function () {
        var _a = createTimer(), thirtySeconds = _a.thirtySeconds, fifteenMinutes = _a.fifteenMinutes, fiveSeconds = _a.fiveSeconds;
        var lastBuiltLocal = useCheckBuildTime(fifteenMinutes).lastBuiltLocal;
        var refreshTicks = FIFTEEN_MINUTES / FIVE_SECONDS;
        var countDownToRefresh = computed(function () { return refreshTicks - (fiveSeconds.value % refreshTicks); });
        return {
            quotes: quotes,
            itemLists: itemLists,
            thirtySeconds: thirtySeconds,
            countDownToRefresh: countDownToRefresh,
            lastBuiltLocal: lastBuiltLocal
        };
    }
});
