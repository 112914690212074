import { render, staticRenderFns } from "./Items.vue?vue&type=template&id=14a0ed40&scoped=true&"
import script from "./Items.vue?vue&type=script&lang=ts&"
export * from "./Items.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14a0ed40",
  null
  
)

export default component.exports